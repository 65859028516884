const images = require.context("../images", true);
const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime";
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";
import toastr from "toastr";
import "bootstrap";
import "@fortawesome/fontawesome-free/css/all";
import "controllers";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import flatpickr from "flatpickr";
require("flatpickr/dist/flatpickr.css");
import Swal from 'sweetalert2';

import moment from 'moment';

window.intlTelInput = intlTelInput;
window.flatpickr = flatpickr;
window.moment = moment;
window.imagePath = imagePath;
window.Swal = Swal;
window.toastr = toastr;


Rails.start()
Turbolinks.start()
