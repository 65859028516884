import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ["item", "note"]

	saveFormElement() {
		const url = this.element.dataset.url
		Rails.ajax({
			type: 'PUT',
			url: url,
			data: new FormData(this.element)
		})
	}
}
