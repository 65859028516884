import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["pages", "tabs", "label", "notes"]

  connect() {
    this.tabsTarget.classList.add('active')
  }

  fetchForm(event) {
    const { url, action } = event.target.dataset
    const ifFetch = event.target.dataset.notfetch ? false : true
    // only swap in a new form on a click event if url is defined
    if (url && ifFetch && !action) {
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          // load the new form in the placeholder
          this.pagesTarget.innerHTML = data.page
        }
      })
      this.updateCSSActive(event)
    }
  }

  updateCSSActive(event) {
    this.tabsTargets.forEach((element) => {
      element.classList.remove('active')

      if (element.dataset.url === event.target.dataset.url) {
        element.classList.add('active')
      }
    })
  }

  loadNotes(event) {
    this.pagesTarget.innerHTML = this.notesTarget.outerHTML
    this.notesTarget.hidden = false
    this.updateCSSActive(event)
  }
}
